<template>
  <div class="card card-profile">
    <div class="row justify-content-center">
      <div class="col-lg-3 order-lg-2">
        <avatar :username="fullName" :size="120" :lighten="90"></avatar>
      </div>
    </div>

    <div class="card-body pt-0">
      <div class="row">
        <div class="col">
          <div class="card-profile-stats d-flex justify-content-center">
            <div>
              <span class="heading">{{ orderNumbers }}</span>
              <span class="description">Zamówienia</span>
            </div>
            <div>
              <span class="heading">{{ points }}</span>
              <span class="description">Punkty</span>
            </div>
          </div>
        </div>
      </div>

      <div class="text-center">
        <h5 class="h3">{{ fullName }}<span class="font-weight-light" v-if="age">, {{ age }}</span></h5>
        <div class="h5 font-weight-300">
          {{ city }}
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="card-profile-stats d-flex justify-content-center">
            <ul class="list-unstyled my-4">
              <li>
                <div class="d-flex align-items-center">
                  <div>
                    <div class="icon icon-xs icon-shape">
                      <i class="fas fa-envelope"></i>
                    </div>
                  </div>
                  <div>
                    <span class="pl-2 text-sm">{{ email }}</span>
                  </div>
                </div>
              </li>
              <li>
                <div class="d-flex align-items-center">
                  <div>
                    <div class="icon icon-xs icon-shape">
                      <i class="fas fa-phone"></i>
                    </div>
                  </div>
                  <div>
                    <span class="pl-2 text-sm">{{ phone }}</span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import Avatar from "@/components/Avatar";
import moment from "moment";
import {polishNumerals} from "@/util/gramma"

export default {
  name: "CustomerCard",
  components: {
    Avatar,
  },
  props: {
    customer: {
      type: Object,
    },
  },
  computed: {
    fullName() {
      return `${this.customer.firstName} ${this.customer.lastName}`;
    },
    orderNumbers() {
      return this.customer.orders.length;
    },
    age() {
      const age = moment.duration(new Date() - new Date(this.customer.birthDate)).years();
      return age ? polishNumerals(age, ['{num} rok', '{num} lata', '{num} lat']) : '';
    },
    city() {
      return this.customer.address.city;
    },
    points() {
      return this.customer.points;
    },
    email() {
      return this.customer.email;
    },
    phone() {
      return this.customer.phone;
    },
  },
};
</script>

<style>
.card-profile div div .vue-avatar--wrapper {
  margin-top: 20px;
}
</style>
