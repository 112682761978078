<template>
  <div class="card">
    <div class="card-header border-0">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0">Zamówienia</h3>
        </div>
      </div>
    </div>

    <el-table class="table-responsive table"
              :data="tableData"
              header-row-class-name="thead-light">
      <el-table-column label="ID"
                       min-width="120px"
                       prop="id">
        <template v-slot="{row}">
          <router-link :to="{name: 'order-view', params: {id: row.id}}" class="font-weight-bold">
            {{ row.id }}
          </router-link>
        </template>
      </el-table-column>
      <el-table-column label="Numer zamówienia"
                       min-width="80px"
                       prop="number">
      </el-table-column>
      <el-table-column label="Data"
                       min-width="70px"
                       prop="date">
      </el-table-column>
      <el-table-column label="Kwota"
                       min-width="70px"
                       prop="totalAmount">
      </el-table-column>
      <el-table-column label="Status"
                       min-width="70px"
                       prop="status">
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import {Table, TableColumn} from "element-ui";

export default {
  name: "CustomerOrderList",
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  props: {
    customer: {
      type: Object,
    },
  },
  computed: {
    tableData() {
      return this.customer.orders;
    },
  },
};
</script>
