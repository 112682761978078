<template>
  <div>
    <base-header class="pb-6" type="primary">
      <breadcrumb-header :items="breadcrumb"></breadcrumb-header>
    </base-header>

    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl-4">
          <customer-card :customer="itemData"></customer-card>
        </div>
        <div class="col-xl-8">
          <customer-order-list :customer="itemData"></customer-order-list>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {createNamespacedHelpers} from 'vuex'

import BaseHeader from '@/components/BaseHeader';

import CustomerCard from "@/views/Store/Customer/CustomerCard";
import CustomerOrderList from "@/views/Store/Customer/CustomerOrderList";
import BreadcrumbHeader from "@/views/Store/Layout/BreadcrumbHeader";

const {mapState} = createNamespacedHelpers('CustomerViewModule');

export default {
  name: 'CustomerView',
  components: {
    BreadcrumbHeader,
    BaseHeader,

    CustomerCard,
    CustomerOrderList,
  },
  computed: {
    ...mapState({
      itemData: state => state.item,
    }),
    breadcrumb() {
      return [
        {title: 'Klienci', link: {name: 'clients'}},
        {title: 'Detaliczni', link: {name: 'customer-list'}},
        {title: `Klient ID: ${this.$route.params.id}`}
      ];
    },
  },
  beforeMount() {
    this.$store.dispatch('CustomerViewModule/fetchItem', {
      token: this.$store.state.Auth.token,
      id: this.$route.params.id,
    });
  },
};
</script>
